import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { hotjar } from 'react-hotjar';
import { useLocalStorage } from '@mantine/hooks';
import type { UserGroup, UserType } from '../types/user';
import { AUTH_API_HOST } from '../buildConfig/processEnv';
import { getNextUrl } from '../utils/urls';

export const IS_PROD = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

const credentials = {
    username: 'frontlinesales',
    password: 'arenalabatt',
};

type AppContextType = {
    user: UserType;
    handleUser: (user: UserType, reroute?: boolean) => void;
    verifyToken: (token: string, isSiberia?: boolean) => Promise<void>;
    googleSiberiaLogin: () => void;
    microsoftSSOSiberiaLogin: () => void;
    credentialLogin: ({
        username,
        password,
    }: {
        username: string;
        password: string;
    }) => Promise<string>;
    logOutSiberia: (token: string) => void;
    fetchingToken?: boolean;
    rawUser?: UserType;
    setRawUser: React.Dispatch<React.SetStateAction<UserType>>;
};

type CredentialLogin = {
    user: {
        id: number;
        username: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: UserGroup[];
        show_user_details_prompt: boolean;
    };
};

const AppContext = createContext<AppContextType>({
    handleUser(user) {},
    verifyToken(token: string) {
        return Promise.reject(new Error());
    },
    googleSiberiaLogin() {},
    microsoftSSOSiberiaLogin() {},
    logOutSiberia() {},
    user: {},
    credentialLogin: async ({ username, password }) => '',
    rawUser: undefined,
    setRawUser: () => {},
});
AppContext.displayName = 'AppContext';

function AppContextProvider({ children }: { children: React.ReactNode }) {
    const { isReady, query, ...router } = useRouter();
    const currentRouteRef = React.useRef<string>();
    const [user, setUser] = React.useState<UserType>({
        isLoggedIn: false,
        displayDeveloperTools: 'show',
    });

    // Raw API Response
    const [rawUser, setRawUser] = React.useState<UserType>();
    const [savedGroups, setSavedGroups] = useLocalStorage<UserType['groups'] | 'undefined'>({
        key: 'saved-groups',
        defaultValue: [],
    });
    const [displayDeveloperTools, setDisplayDeveloperTools] = useLocalStorage<
        'show' | 'hide' | 'undefined'
    >({
        key: 'saved-displayDeveloperTools',
        defaultValue: 'show',
    });
    const handleUser = useCallback<AppContextType['handleUser']>(
        newUserData => {
            setUser(prev => ({ ...prev, ...newUserData }));
            if (newUserData?.groups) setSavedGroups(newUserData.groups);
            if (
                !!newUserData?.displayDeveloperTools &&
                newUserData?.displayDeveloperTools !== user.displayDeveloperTools
            ) {
                setDisplayDeveloperTools(newUserData.displayDeveloperTools);
            }
        },
        [setDisplayDeveloperTools, setSavedGroups, user.displayDeveloperTools],
    );

    const generateSiberiaUserToken = useCallback(async () => {
        if (
            JSON.parse(localStorage.getItem('user-data') || 'null')?.groups?.includes('luna-users')
        ) {
            return { token: 'token', isLoggedIn: true };
        }

        const response = await fetch(`${AUTH_API_HOST}/auth-service/api/auth/tokens/generate/`, {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            credentials: 'include',
        });

        if (response.ok) {
            const { token } = await response.json();
            setUser(prev => ({ ...prev, siberiaToken: token, isLoggedIn: true }));
            localStorage.setItem('siberiaToken', token);
            return { token, isLoggedIn: true };
        }
        // ? Token is set to [error] to prevent infinite loop.
        return { token: false, isLoggedIn: !user?.siberiaToken && false };
    }, [user?.siberiaToken]);

    const { data: userSiberiaToken, isLoading: fetchingSiberiaToken } = useQuery({
        queryKey: ['token', 'siberia'],
        queryFn: generateSiberiaUserToken,

        staleTime: 24 * 60 * 60 * 1000,
    });
    useEffect(() => {
        if (!isReady) return;
        if (currentRouteRef.current) {
            router.push(currentRouteRef.current);
            currentRouteRef.current = undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchingSiberiaToken, isReady]);

    const { data: userSiberiaData } = useQuery({
        queryKey: ['Auth', 'UserSiberia'],
        queryFn: async () => {
            const response = await fetch(`${AUTH_API_HOST}/auth-service/api/auth/user/`, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Token ${
                        localStorage.getItem('siberiaToken') || user?.siberiaToken
                    }`,
                },
                method: 'GET',
                credentials: 'include',
            });
            const res = await response.json();
            if (res?.id) {
                window.heap?.identify(res.username);
                hotjar.identify(
                    (res.username as string).replace('.com', '').replace(/[@.]/g, '-'),
                    {
                        groups: res.groups,
                        isArenaStaff: res.groups && res.groups.includes('arena-staff'),
                    },
                );

                const resDataFormatted = {
                    groups: res?.groups,
                    username: res?.username,
                    id: res?.id,
                    showUserDetails: res?.show_user_details_prompt,
                    firstName: res?.first_name,
                    lastName: res?.last_name,
                    email: res?.email,
                };
                localStorage.setItem('user-data', JSON.stringify(resDataFormatted));
                setUser(prev => ({ ...prev, ...resDataFormatted }));
                setRawUser(prev => ({ ...prev, ...resDataFormatted }));
                return resDataFormatted;
            }
            return res;
        },
        enabled: !!userSiberiaToken?.token,
        staleTime: 24 * 60 * 60 * 1000,
        retry: 2,
    });

    const verifyToken = React.useCallback(
        async (token?: string, isSiberia?: boolean) => {
            if (!token) return;
            const response = await fetch(`${AUTH_API_HOST}/auth-service/api/auth/tokens/verify/`, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Token ${token}`,
                },
                method: 'GET',
                credentials: 'include',
            });
            if (response.ok && token !== user?.token) {
                setUser(prev => ({
                    ...prev,
                    ...{ [isSiberia ? 'siberiaToken' : 'token']: token },
                    isLoggedIn: true,
                }));
                return;
            }
            if (response.status >= 400) {
                if (user?.token)
                    setUser(prev => ({
                        ...prev,
                        isLoggedIn: !prev?.siberiaToken && !prev?.token && false,
                    }));
                localStorage.removeItem('token');
            }
        },
        [user?.token],
    );

    const nextUrl = useMemo(() => {
        if (!isReady) return '';
        const DEFAULT_NEXT_URL = window.location.origin;
        return getNextUrl(DEFAULT_NEXT_URL + router.asPath);
    }, [isReady, router.asPath]);

    const googleSiberiaLogin = useCallback(() => {
        localStorage.setItem('siberiaBtnClicked', 'true');
        location.href = `${AUTH_API_HOST}/auth-service/sso-v2/login/google-oauth2?next=${encodeURIComponent(
            nextUrl,
        )}`;
    }, [nextUrl]);

    const microsoftSSOSiberiaLogin = useCallback(() => {
        localStorage.setItem('siberiaBtnClicked', 'true'); // not maintainable, remove afterwards

        location.href = `${AUTH_API_HOST}/auth-service/sso-v2/login/azuread-oauth2?next=${encodeURIComponent(
            nextUrl,
        )}`;
    }, [nextUrl]);

    const credentialLogin = useCallback<AppContextType['credentialLogin']>(
        async ({ username, password }: { username: string; password: string }) => {
            if (!username || !password) return new Error('Provide Username and Password');

            if (username === credentials.username && password === credentials.password) {
                setUser(prev => ({
                    ...prev,
                    isLoggedIn: true,
                    username: credentials.username,
                    groups: ['luna-users'],
                }));
                setRawUser(prev => ({
                    ...prev,
                    isLoggedIn: true,
                    username: credentials.username,
                    groups: ['luna-users'],
                }));
                return 'success';
            }

            const response = await fetch(`${AUTH_API_HOST}/auth-service/api/auth/login/`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    username,
                    password,
                }),
            });

            if (response.status >= 400) {
                const errorMassage = await response.json();
                return errorMassage?.detail;
            }

            const res: CredentialLogin = await response.json();
            const resFormatted = {
                groups: res.user.groups,
                username: res.user.username,
                id: res.user.id.toString(),
                showUserDetails: res.user.show_user_details_prompt,
                firstName: res.user.first_name,
                lastName: res.user.last_name,
                email: res.user.email,
            };
            setUser(resFormatted);
            setRawUser(resFormatted);
            generateSiberiaUserToken();
            return 'success';
        },
        [generateSiberiaUserToken, setRawUser],
    );

    const logOutSiberia = React.useCallback(async (token?: string) => {
        const response = await fetch(`${AUTH_API_HOST}/auth-service/api/auth/logout`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Token ${token}`,
            },
            method: 'GET',
            credentials: 'include',
        });
        if (response.ok) {
            localStorage.removeItem('siberiaToken');
            setUser(prev => ({ ...prev, isLoggedIn: false, siberiaToken: undefined }));
        }
    }, []);

    useEffect(() => {
        // ? Other wise by the time we get userToken verify will override the token with undefined
        if (fetchingSiberiaToken) return;
        const storedToken = localStorage.getItem('token');
        const storedSiberiaToken = localStorage.getItem('siberiaToken');
        if (!storedToken && !user?.token) return;
        if (storedSiberiaToken) verifyToken(storedSiberiaToken, true);
        // Hack for incognito mode where localStorage might not be available
    }, [fetchingSiberiaToken, user?.token, verifyToken]);

    const value = React.useMemo(
        (): AppContextType => ({
            user: {
                ...user,
                ...userSiberiaData,
                ...user,
                groups:
                    savedGroups !== 'undefined' && savedGroups?.length > 0
                        ? savedGroups
                        : user?.groups,
                displayDeveloperTools:
                    displayDeveloperTools !== 'undefined'
                        ? displayDeveloperTools
                        : (user?.displayDeveloperTools ?? 'hide'),
            },
            handleUser,
            verifyToken,
            googleSiberiaLogin,
            microsoftSSOSiberiaLogin,
            credentialLogin,
            logOutSiberia,
            fetchingToken: fetchingSiberiaToken,
            rawUser,
            setRawUser,
        }),
        [
            user,
            userSiberiaData,
            savedGroups,
            displayDeveloperTools,
            handleUser,
            verifyToken,
            googleSiberiaLogin,
            microsoftSSOSiberiaLogin,
            credentialLogin,
            logOutSiberia,
            fetchingSiberiaToken,
            rawUser,
        ],
    );
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export const useAppContext = () => {
    const { handleUser, ...rest } = React.useContext(AppContext);

    if (!handleUser) {
        throw new Error('useAppContext must be used within an AppContextProvider');
    }
    return { handleUser, ...rest };
};

export default AppContextProvider;
export type { AppContextType };
