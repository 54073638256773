const IS_RELEASE = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF?.startsWith?.('release');
export const IS_PROD = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' || IS_RELEASE;

export const API_URL = `https://fusion-${IS_PROD ? 'prod' : 'dev'}.internal.arena-ai.com`;

export const HEAP_ID = process.env.NEXT_PUBLIC_HEAP_ID;
export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
export const AUTH_API_HOST = `https://uranus-api-uat.arena-ai.com`;

// export const URANUS_API_URL = `https://uranus-api-uat.arena-ai.com`;
// export const AUTH_API_HOST = `https://spaceport-api.internal.arena-ai.com`;
export const URANUS_API_URL = IS_PROD
    ? `https://uranus-api-uat.arena-ai.com`
    : `https://spaceport-api.internal.arena-ai.com`;

export const SPACEPORT_API_URL = `https://spaceport-api.internal.arena-ai.com`;
export const PROMO_API_URL = `https://spaceport-api.internal.arena-ai.com`;

export const MAPBOX_ACCESS_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;
